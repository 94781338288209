import uuidv1 from 'uuid/v1';

// HEAD DATA
export const headData = {
  title: 'Gian Aguirre', // e.g: 'Name | Developer'
  lang: 'en', // e.g: en, es, fr, jp
  description: '', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: 'Hello! My name is',
  name: 'Gian Aguirre',
  subtitle: 'I enjoy solving problems and creating pieces of art.',
  cta: 'Snoop Around',
};

// ABOUT DATA
export const aboutData = {
  img: 'profile.jpg',
  paragraphOne: 'I have been a professional web developer since 2014. I started building websites long before that, learning how to code from books and geocities!',
  paragraphTwo: 'When I\'m not deep in code, I can be found working some papercrafts to raise funds for the improvement of the local comunity.',
  paragraphThree: 'I also enjoy home automation. A smart home does things automatically. If I have to use my voice or an app, it\'s not smart.',
  // resume: 'https://cloud.giyo.us/s/Tf9Lns4LDrjqfyp/download/Aguirre_Gian_Resume.pdf', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: uuidv1(),
    img: 'oro.png',
    title: 'Mask Reservation',
    info: 'I was able to carve out some hours to create a mask reservation system for a local mask maker who was only asking donations for their time.',
    info2: 'People could reserve custom sizes/mask options and the fabric. My favorite feature was preventing people from reserving a pattern that could have been in someones equivalent of a shopping cart. There are no "item no longer available" messages when you are "checking out"!',
    url: 'https://orodev.giyo.us/',
    // repo: 'https://github.com/cobidev/react-simplefolio', // if no repo, the button will not show up
  },
  {
    id: uuidv1(),
    img: '3dprintpaper.jpg',
    title: 'Experimentation',
    info: 'I have been combining 3D printing and paper engineering to see what can be made with the two.',
    info2: 'I added some RGB LEDS, cause everyone loves that, and it turned out to be a cool project.',
    // url: '',
    // repo: 'https://github.com/cobidev/react-simplefolio', // if no repo, the button will not show up
  },
  // {
  //   id: uuidv1(),
  //   img: 'project.jpg',
  //   title: '',
  //   info: '',
  //   info2: '',
  //   url: '',
  //   repo: 'https://github.com/cobidev/react-simplefolio', // if no repo, the button will not show up
  // },
];

// CONTACT DATA
export const contactData = {
  cta: 'Want to work with me? You know what to do',
  btn: 'HMU',
  email: 'gian+portfolio@giyo.us',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: uuidv1(),
      name: 'hand-spock-o',
      url: 'https://faq.giyo.us',
    },
    {
      id: uuidv1(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/gianaguirre',
    },
    {
      id: uuidv1(),
      name: 'github',
      url: 'https://github.com/omgian',
    },
    {
      id: uuidv1(),
      name: 'camera-retro',
      url: 'https://peeps.giyo.us/share/0_c623GrAmaZvIqpiTtPC9JrG37cwCUnwvQ0v-AtwX46ICu8m9XYe7IwcMyOKVTNJSk',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
